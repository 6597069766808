.tabs {
  display: flex;
  flex-direction: column;

  nav {
    margin-bottom: 3.75rem;

    button {
      margin-right: 0.75rem;
      font-size: 1.5rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 3.75rem;

    @include mobile {
      justify-content: center;
    }

    a {
      text-decoration: none;
      margin-bottom: 0;
    }
  }

  .button-primary {
    align-self: center;
  }

  @include desktop-medium {
    nav {
      margin-bottom: 1rem;

      button {
        margin-bottom: 1rem;
      }
    }
  }

  @include mobile {
    nav {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 1rem;

      button {
        margin-bottom: 1rem;
        font-size: 1rem;
      }
    }

    .tabs__content {
      flex-direction: column;
    }
  }
}