.button-primary {
  border: none;
  background: none;
  font-family: NMMADSansFill-Regular, Arial, Helvetica, sans-serif;
  font-size: 1.75rem;
  cursor: pointer;
  position: relative;
  text-align: center;
  display: inline-block;
  max-width: 100%;
  z-index: 1;

  &::after {
    border-color: #fff;
    width: 130%;
    top: 1.4375rem;
    left: -15%;
    right: 0;
    content: "";
    position: absolute;
    border-width: 0.125rem;
    border-style: solid;
    border-radius: 100%;
    height: 80%;
    z-index: -1;
  }
  &:hover::after {
    background-color: #fff;
  }
}

.button-secondary-white {
  background-color: #fff;
  color: #000;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  border: 1px solid #000;
  cursor: pointer;
  font-family: Diatype-light, Arial, Helvetica, sans-serif;
  font-size: 1rem;

  &:hover {
    background-color: #000;
    border-color: #fff;
    color: #fff;
  }
}

.button-secondary-black {
  background-color: #000;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  border: 1px solid #fff;
  cursor: pointer;
  font-family: Diatype-light, Arial, Helvetica, sans-serif;
  font-size: 1rem;

  &:hover {
    background-color: #fff;
    border-color: #000;
    color: #000;
  }

  &:active, &:focus {
    background-color: #000;
    border-color: #fff;
    color: #fff;
  }

}

.link-button {
  cursor: pointer;
  border: none;
  background: none;
  font-family: Diatype-Light, Arial, Helvetica, sans-serif;
  font-size: 1.5625rem;
  text-decoration: underline;
  color: black;

  &::after{
    content: none;
  }
  &:hover::after {
    content: none;
  }
}

.circle-button {
  position: fixed;
  right: 3.125rem;
  bottom: 6.75rem;
  cursor: pointer;
  border: solid 5px $yellow;
  border-radius: 50%;
  width: 8.75rem;
  height: 8.75rem;
  box-shadow: 1px 1px 10px -3px rgba(0,0,0,0.5);
  background-color: $yellow;
  background-image: url("../../public/assets/images/yellow-circle.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(0deg);
  z-index: $zCircleBtn;

  transition: all 2s ease;

  &:hover {
    transform: rotate(360deg);
    transition: all 5s ease;
  }

  @include tablet {
    max-width: 100px;
    max-height: 100px;
    width: 25vw;
    height: 25vw;
    right: 1rem;
    bottom: 1rem;
    background-image: url("../../public/assets/images/yellow-circle-mobile.png");
  }
}

model-viewer[ar-status="session-started"], model-viewer[ar-status="object-placed"], model-viewer[ar-status="failed"] {
  .circle-button {
    display: none;
  }
}

#custom-ar-button {
  display: flex;
  max-width: 100px;
  max-height: 100px;
  width: 25vw;
  height: 25vw;
  border: none;
  border-radius: 20%;
  left: 1rem;
  position: fixed;
  right: 2.5rem;
  bottom: 1rem;
  cursor: pointer;
  box-shadow: 1px 1px 10px -3px rgb(0 0 0 / 50%);
  background-color: #000;
  color: #fff;
  font-family: Diatype-Light, Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    background-image: url("../../public/assets/icons/ar-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 2.5rem;
    height: 2.5rem;
    padding-bottom: 0.5rem;
  }
}


