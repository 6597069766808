@font-face { font-family: Diatype-Light;
  src: url('../../public/assets/fonts/ABCDiatype-Light.woff2') format("woff2"),
  url('../../public/assets/fonts/ABCDiatype-Light.woff') format("woff");  }

@font-face { font-family: NMMADSans-Regular;
  src: url('../../public/assets/fonts/NMMADSans-Regular.woff2') format('woff2'),
  url('../../public/assets/fonts/NMMADSans-Regular.woff') format("woff"); }

@font-face { font-family: NMMADSansFill-Regular;
  src: url('../../public/assets/fonts/NMMADSansFill-Regular.woff2') format("woff2"),
  url('../../public/assets/fonts/NMMADSansFill-Regular.woff') format("woff"); }