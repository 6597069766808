/*
320px — 480px: Mobile devices.
481px — 768px: iPads, Tablets.
769px — 1024px: Small screens, laptops.
1025px — 1200px: Desktops, large screens.
1201px and more — Extra large screens, TV.
*/

@mixin mobile {
  @media (max-width: 599px) { @content; }
}
@mixin tablet {
  @media (max-width: 1199px) { @content; }
}
@mixin desktop-medium {
  @media (max-width: 1799px) { @content; }
}
@mixin desktop-large {
  @media (min-width: 1800px) { @content; }
}