.popup {
  background-color: $grey;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 15%);
  bottom: 1.25rem;
  right: 1.25rem;
  border-radius: 20px;
  padding: 1.5rem 1.5rem 0 1.5rem;
  position: fixed;
  max-height: 64rem;
  height: calc(100vh - 8.25rem);
  width: calc(100% - 2.5rem);
  max-width: 400px;
  z-index: $zPopup;
  transform: translate(0%, 0%);
  transition: transform 0.25s ease;
  overflow-y: scroll;
  box-sizing: border-box;


  @include mobile {
    width: calc(100vw - 2rem);
    right: 1rem;
    left: 1rem;
    bottom: 1rem;
    top: 1rem;
    max-height: 100vh;
    height: calc(100vh - 2rem);
  }

  &--hidden {
    transform: translateX(110%);

    @include mobile {
      transform: translateY(110%);
    }
  }

  &-wrapper {
    position: sticky;
    bottom: 1rem;
    right: 1rem;
  }

  .button-secondary-black {
    position: absolute;
    bottom: 1rem;
    right: 0;
  }

  &-container {
    padding-bottom: 3rem;
    h3 {
      padding: 0.3125rem 0.8125rem 0.625rem 0.8125rem;
      border-radius: 0.9375rem;
      background-color: $blue;
      border-color: transparent;
      color: #ffffff;
      font-size: 2rem;
    }

    span, .pill {
      display: inline-block;
      background-color: #fff;
      color: $blue;
      padding: 5px 18px;
      border-radius: 50px;
      border: 1px solid $blue;
      font-family: Diatype-Light, Arial, Helvetica, sans-serif;
      font-size: 1.25rem;
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}