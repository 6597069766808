h1, h2, .h2 {
  font-family: NMMADSans-Regular, Arial, Helvetica, sans-serif;
  line-height: 1.5;
  font-size: 5rem;
  font-weight: lighter;

  @include desktop-medium {
      margin: 2rem 0;
      font-size: 4.5rem;
  }

  @include mobile {
    font-size: 2.75rem;
  }
}

h3, .h3 {
  font-family: NMMADSansFill-Regular, Arial, Helvetica, sans-serif;
  font-size: 2.5rem;
  line-height: 1.1;
  font-weight: lighter;

  @include tablet {
    font-size: 2rem;
  }
}

p {
  font-family: Diatype-Light, Arial, Helvetica, sans-serif;
  line-height: 1.5;
  font-size: 1.5625rem;
  font-weight: lighter;

  @include desktop-medium {
    p, p > a {
      font-size: 1.375rem;
    }
  }

  @include mobile {
    font-size: 1.25rem;
  }
}

a {
  font-family: Diatype-Light, Arial, Helvetica, sans-serif;
  font-size: 1.5625rem;
  text-decoration: underline;
  text-decoration-thickness: from-font;
  color: black;
  display: inline-flex;
  align-items: center;

  &:active, &:visited {
    color: black;
  }

  @include mobile {
    font-size: 1.25rem;
  }
}